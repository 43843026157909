/* Note: use only to override very specific theme styles. 
All other css rules should be written in their own respective sass documents. */

/* MOBILE DRAWER MENU */
.content-section--featured .content-section {
  padding: 60px 200px 0 200px !important;
}

.featured-listing {
  padding: 0 30px 0 200px;
  margin-top: 30px;
  margin-bottom: 110px;
}

.featured-listing .slick-dots {
  bottom: -30px;
}

.featured-listing .slick-dots li button::before {
  color: #333333;
}

.featured-item {
  max-width: 350px;
  border: 0px solid rgb(209, 209, 213);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
  overflow: hidden;
}

.featured-item.has-link:hover {
  cursor: pointer !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px 0px;
}

.featured-listing .slick-current {
  padding-left: 5px;
}

.featured-item .image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 170px;
}

.featured-item .content {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  height: 170px;
  padding: 8px;
}

.featured-item .content .title {
  font-size: 22px;
  font-weight: 500;
}

.featured-item .content .address {
  font-size: 18px;
}

.featured-item .content .mls {
  font-size: 16px;
  font-weight: 700 !important;
  opacity: 0.5 !important;
  margin-top: auto;
}

.featured-item .content .logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 35px;
  opacity: 0.4;
}

@media screen and (max-width: 768px) {
  .content-section--featured .content-section {
    padding: 30px 30px 0 30px !important;
  }

  .featured-listing {
    padding: 0 30px 50px 30px;
    margin: 0;
  }
}

.agents-listing--icon-block {
  display: flex;
  column-gap: 10px;
}

.agents-listing--icon-block button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: auto !important;
  aspect-ratio: 1/1 !important;
  border-radius: 50% !important;
  transition: transform 0.1s ease-in;
}

.agents-listing--icon-block button:hover {
  transform: scale(1.2);
}

.list-block-tool-tip {
  min-width: 120px;
}